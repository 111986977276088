<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  mounted() {
    this.setLanguage();
  },
  methods: {
    // set the language of the app
    setLanguage() {
      // set the language
      var browserLanguage =
        window.navigator.userLanguage || window.navigator.language;
      var language = "en-us";
      /*
      if (browserLanguage.indexOf("es-") === 0) {
        language = "es";
      }
      */
      // this.$store.dispatch("auth/setBrowserLanguage", language);
      // this.$store.dispatch("auth/setLanguage", language);
      // console.log(this);
      this.$i18n.locale = language;
    },
  },
});
</script>

<style>
#q-app {
  /* max-width: 680px; */
  margin: 0 auto;
}
.q-header {
  /* max-width: 680px; */
  margin-left: auto;
  margin-right: auto;
}
</style>
