const routes = [
  {
    path: "/login",
    meta: { requiresAuth: false },
    component: () => import("layouts/LoginLayout.vue"),
    children: [{ path: "", component: () => import("pages/login/Login.vue") }],
  },
  {
    path: "/signup",
    meta: { requiresAuth: false },
    component: () => import("layouts/LoginLayout.vue"),
    children: [{ path: "", component: () => import("pages/login/Login.vue") }],
  },
  {
    path: "/logout",
    meta: { requiresAuth: false },
    component: () => import("layouts/LoginLayout.vue"),
    children: [
      { path: "", component: () => import("src/pages/login/LogoutPage.vue") },
    ],
  },
  {
    path: "/forgot",
    meta: { requiresAuth: false },
    component: () => import("layouts/LoginLayout.vue"),
    children: [{ path: "", component: () => import("pages/login/Forgot.vue") }],
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Index.vue"),
        meta: { title: "golfity" },
      },
      {
        path: "contact",
        component: () => import("pages/Contact.vue"),
        meta: { title: "Contact us" },
      },
      {
        path: "profile",
        component: () => import("src/pages/ProfilePage.vue"),
        meta: { title: "golfity" },
      },
      {
        path: "clubs",
        component: () => import("src/pages/ClubsPage.vue"),
        meta: { title: "golfity" },
      },
      {
        path: "export",
        component: () => import("pages/Export.vue"),
        meta: { title: "golfity" },
      },
      {
        path: "calculator",
        component: () => import("pages/Calculator.vue"),
        meta: { title: "Strokes Gained Calculator", guestAllowed: true },
      },
      {
        path: "courses",
        component: () => import("pages/Courses.vue"),
        meta: { title: "Courses" },
      },
      {
        path: "courses/:query",
        component: () => import("pages/Courses.vue"),
        meta: { title: "Courses" },
      },
      {
        path: "course/:courseId",
        component: () => import("src/pages/CoursePage.vue"),
        meta: { title: "Course", guestAllowed: true },
      },
      {
        path: "start-round/:courseId",
        component: () => import("pages/StartRound.vue"),
        meta: { title: "Start Round" },
      },
      {
        path: "input-round-total",
        component: () => import("pages/InputRoundTotal.vue"),
        meta: { title: "Enter Round" },
      },
      {
        path: "edit-round-total/:roundId",
        component: () => import("pages/EditRoundTotal.vue"),
        meta: { title: "Edit Round" },
      },
      {
        path: "input-round-holes",
        component: () => import("pages/InputRoundHoles.vue"),
        meta: { title: "Enter Round" },
      },
      {
        path: "edit-round-holes/:roundId",
        component: () => import("pages/EditRoundHoles.vue"),
        meta: { title: "Edit Round" },
      },
      {
        path: "input-round-shots",
        component: () => import("pages/InputRoundShots.vue"),
        meta: { title: "Enter Round" },
      },
      {
        path: "edit-round-shots/:roundId",
        component: () => import("pages/EditRoundShots.vue"),
        meta: { title: "Edit Round" },
      },
      {
        path: "play",
        component: () => import("pages/Play.vue"),
        meta: { title: "Play Round" },
      },
      {
        path: "user/:userId/stats",
        component: () => import("pages/UserStats.vue"),
        meta: { title: "User Stats" },
      },
      {
        path: "user/:userId/rounds",
        component: () => import("pages/UserRounds.vue"),
        meta: { title: "User Rounds" },
      },
      {
        path: "round/:roundId",
        component: () => import("pages/Round.vue"),
        meta: { title: "Round", guestAllowed: true },
      },

      {
        path: "round/:roundId/stats",
        component: () => import("pages/RoundStats.vue"),
        meta: { title: "Round Stats", guestAllowed: true },
      },
      {
        path: "round/:roundId/shots",
        component: () => import("pages/RoundShots.vue"),
        meta: { title: "Round Shots", guestAllowed: true },
      },
      {
        path: "invite",
        component: () => import("pages/InviteFriends.vue"),
        meta: { title: "Invite Friends", guestAllowed: false },
      },
      {
        path: "user/compare/:userId1/:userId2",
        component: () => import("pages/UsersCompare.vue"),
        meta: { title: "Compare Players" },
      },
      {
        path: "subscribe",
        component: () => import("pages/PaywallPage.vue"),
        meta: { title: "Subscribe" },
      },
    ],
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("layouts/MainLayoutWhite.vue"),
    children: [
      {
        path: "user/:userId",
        component: () => import("pages/User.vue"),
        meta: { title: "User" },
      },
    ],
  },

  {
    path: "/input-hole",
    meta: { requiresAuth: true },
    component: () => import("layouts/InputHoleLayout.vue"),
    children: [
      {
        path: ":holeNumber",
        component: () => import("pages/InputHole.vue"),
        meta: { title: "Enter Hole" },
      },
    ],
  },
  {
    path: "/input-hole-shots",
    meta: { requiresAuth: true },
    component: () => import("layouts/InputHoleLayout.vue"),
    children: [
      {
        path: ":holeNumber",
        component: () => import("pages/InputHoleShots.vue"),
        meta: { title: "Enter Hole" },
      },
    ],
  },
  {
    path: "/round",
    meta: { requiresAuth: true },
    component: () => import("layouts/InputHoleLayout.vue"),
    children: [
      {
        path: ":roundId/hole/:holeNumber",
        component: () => import("pages/RoundHole.vue"),
        meta: { title: "Round Hole" },
      },
    ],
  },
  {
    path: "/round",
    meta: { requiresAuth: true },
    component: () => import("layouts/EmptyLayout.vue"),
    children: [
      {
        path: "created/:roundId",
        component: () => import("pages/RoundCreated.vue"),
        meta: { title: "Round Created" },
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
